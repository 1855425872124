import type * as Types from 'design/components/SocialCard/SocialCard.types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React from 'react'
import makeRichTextOptions from '~/cms/rich-text'
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  CardMedia,
  Text,
} from '@butcherbox/freezer'
import BackgroundImage from 'gatsby-background-image'
import * as Styles from './SocialCard.css'
/**
 * A Social card is a single piece of UGC that appears within the Module Social Cards
 * on landers.
 */

const SocialCard = React.forwardRef<HTMLDivElement, Types.SocialCardProps>(
  ({ avatar, image, name, text, ...props }, ref) => {
    if (!avatar || !image || !name || !text) return null

    return (
      <Box py={8}>
        <Card
          boxShadow="default"
          className={Styles.SocialCard}
          data-what="social-card"
          ref={ref}
          {...props}
        >
          <CardHeader alignItems="center" display="flex">
            <Box className={Styles.AvatarContainer}>
              <BackgroundImage className={Styles.Image} fixed={avatar.fixed} />
            </Box>
            <Text component="span" variant="Body2Bold">
              {name}
            </Text>
          </CardHeader>
          <CardMedia
            alt={name}
            renderMedia={() => (
              <BackgroundImage className={Styles.Image} fluid={image.fluid} />
            )}
          ></CardMedia>
          <CardBody>
            {text
              ? renderRichText(
                  text,
                  makeRichTextOptions({
                    renderText: (data) => data.slice(0, 152),
                  })
                )
              : null}
          </CardBody>
        </Card>
      </Box>
    )
  }
)

export default SocialCard
