import { Box, Text } from '@butcherbox/freezer'
import SocialCard from 'design/components/SocialCard/SocialCard'
import React from 'react'
import type * as Types from './ModuleSocialCards.types'
import { Carousel } from '@butcherbox/freezer'
import * as Styles from './ModuleSocialCards.css'

/**
 * Module Social Cards is a wrapper that contains N social cards,
 * these are displayed on desktop as a carousel if there are > 3
 * otherwise, as a grid. And always as a carousel on mobile.
 */
export default function ModuleSocialCards({
  cards,
  component = 'section',
  id,
  ...props
}: Types.ModuleSocialCardsProps) {
  if (!cards || !cards?.length) {
    return null
  }

  return (
    <Box
      aria-labelledby={id}
      className={Styles.ModuleSocialCards}
      component={component}
      {...props}
    >
      <Box className={Styles.RuledHeadline} id={id}>
        <Text display="inline" variant="H2Bold">
          People love{' '}
          <Text
            color="spicedCrimson"
            component="span"
            display="inline"
            variant="H2Bold"
          >
            #ButcherBox
          </Text>
        </Text>
      </Box>

      <Carousel label="Social Media Cards" slideGap={24}>
        {cards.map((card, idx) => {
          if (!card.name) return
          return (
            <li key={`m-${idx}`}>
              <SocialCard {...card} />
            </li>
          )
        })}
      </Carousel>
    </Box>
  )
}
